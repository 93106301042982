import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import { i18n } from '@/i18n';
import { setDocumentTitleByRoute } from '@/functional/setDocumentTitle';

Vue.use(VueRouter);

export const router = new VueRouter({
    // mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    setDocumentTitleByRoute(to, i18n);
    next();
});
