export const Card = class {
    constructor(options) {
        this.options = options;
    }

    get id() {
        return this.options.id;
    }

    get front() {
        return this.options.front;
    }

    get back() {
        return this.options.back;
    }

    get figuredBass() {
        return this.options.figuredBass;
    }

    get scaleDegree() {
        return this.options.scaleDegree;
    }

    get deck() {
        return this.options.deck;
    }

    get deckOrdering() {
        return this.options.deck === 'red' ? 0 : 1;
    }

    get scaleDegreeOrdering() {
        return this.options.scaleDegree === '#4' ? 4.5 : parseInt(this.options.scaleDegree, 10);
    }

    get affectChord() {
        return this.options.affectChord;
    }

    get suspended() {
        return this.options.suspended;
    }

    get enriched() {
        return this.options.enriched;
    }

    get semitoniumModi() {
        return this.options.semitoniumModi;
    }

    get quintaSyncopata() {
        return this.options.quintaSyncopata;
    }

    get majorSixth() {
        return this.options.majorSixth;
    }

    get passingChord() {
        return this.options.passingChord;
    }

    get progression() {
        return this.options.progression || [];
    }

    get saltation() {
        return this.progression.includes('saltation');
    }

    get ascending() {
        return this.progression.includes('ascending');
    }

    get descending() {
        return this.progression.includes('descending');
    }

    get category() {
        if(this.categoryRegola) return 'regola';
        if(this.categoryVariants) return 'variants';
        if(this.categoryOther) return 'other';
        return null;
    }

    get categoryRegola() {
        return this.deck === 'red';
    }

    get categoryVariants() {
        return this.deck === 'blue' && (this.affectChord || this.suspended);
    }

    get categoryOther() {
        return !this.categoryRegola && !this.categoryVariants;
    }

    get categoryOrdering() {
        if(this.categoryRegola && this.enriched) return 1;
        if(this.categoryVariants) return 2;
        if(this.categoryOther) return 3;
        return 0;
    }

    get augmentedSixth() {
        return this.figuredBass.includes('#6');
    }

    get otherOther() {
        return (
            this.id === '6f044950-0654-4dbc-b72c-7c935467690f' // 4-Stufe abwärts ohne Durchgang
            || this.id === '2229ff0f-c0b8-45fc-aaae-005d9a9902b6' // #4
            || this.id === 'b45d5478-44be-434f-9a3e-da55f1743139' // Trugschluss
        );
    }
};
