// https://stackoverflow.com/a/2450976
export const shuffle = function (array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

// https://stackoverflow.com/a/36803792
export const flatten = function(elements, items = 'children') {
    const array = elements.map(element => Object.assign({}, element));
    return array.reduce((accumulator, currentValue) => {
        accumulator = accumulator.concat(currentValue);
        if (currentValue[items]) {
            accumulator = accumulator.concat(flatten(currentValue[items]));
            currentValue[items] = [];
        }
        return accumulator;
    }, []);
};
