<template>
    <div v-click-outside="hide" class="relative">
        <form-group :label="label" :show-label="showLabel">
            <div class="rounded-sm shadow-sm border border-gray-300 transition duration-300 cursor-pointer p-2" @click="toggle">
                <div class="flex flex-row items-center space-x-3">
                    <div class="flex-grow flex-shrink truncate" :class="{'w-0': !autoGrow}">
                        {{ typeof displaySelectedLabels === 'string' ? displaySelectedLabels : displaySelectedLabels.join(', ') }}
                    </div>
                    <div v-if="multiple && value.length" class="flex-grow-0 flex-shrink-0 ml-2 text-gray-500 font-bold text-xs">{{ value.length }}</div>
                    <div class="w-4 h-4 transition duration-300 transform flex-grow-0 flex-shrink-0" :class="{'rotate-180': menuIsOpen}">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="absolute z-10 w-full mt-1 bg-white rounded-sm shadow-sm border border-gray-300 py-2" v-if="menuIsOpen">
                <choice-type-option v-for="choice in choices" :multiple="multiple" :key="choice.value" :choice="choice" :value="value" @click-choice="clickChoice"></choice-type-option>
            </div>
        </form-group>
    </div>
</template>

<script>
import ChoiceTypeOption from '@/components/ChoiceTypeOption';
import FormGroup from '@/components/FormGroup';
import { flatten } from '@/functional/utils';

export default {
    components: {
        ChoiceTypeOption,
        FormGroup,
    },
    props: {
        autoGrow: {
            type: Boolean,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        value: {
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        choices: {
            type: Array,
            default: () => [],
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            menuIsOpen: false,
        };
    },
    computed: {
        displaySelectedLabels() {
            if(this.multiple && this.value.length) {
                return this.value.map(value => {
                    const selectedChoice = this.getChoiceForValue(value);
                    return selectedChoice ?  selectedChoice.label : null;
                }).filter(c => !!c);
            } else {
                const selectedChoice = this.getChoiceForValue(this.value);
                if(selectedChoice) return selectedChoice.label;
            }
            return this.$t('message.noOptionSelected');
        },
        flattenedChoices() {
            return flatten(this.choices);
        },
    },
    methods: {
        getChoiceForValue(value) {
            return this.flattenedChoices.find(choice => choice.value === value);
        },
        toggle() {
            this.menuIsOpen = !this.menuIsOpen;
        },
        hide() {
            this.menuIsOpen = false;
        },
        show() {
            this.menuIsOpen = true;
        },
        clickChoice(choice) {
            if(this.multiple) {
                const value = [...this.value];

                const index = value.indexOf(choice);
                if (index > -1) {
                    value.splice(index, 1);
                } else {
                    value.push(choice);
                }

                this.$emit('input', value);
            } else {
                this.$emit('input', choice);
                this.menuIsOpen = false;
            }
        },
    },
};
</script>
