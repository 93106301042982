<template>
    <layout-with-topbar :title="$t('title.explanationOfTheSymbols')" :small="true">
        <div class="content-container" v-html="content"></div>
        <div class="m-auto sm:w-2/3 md:w-1/2 my-8">
            <card-component :card="card" :flappable="false"></card-component>
        </div>
    </layout-with-topbar>
</template>

<script>
import { Card } from '@/classes/card';
import LayoutWithTopbar from '@/layouts/LayoutWithTopbar';
import CardComponent from '@/components/Card';
import { parseMarkdown } from '@/functional/parse-markdown';

export default {
    components: {
        LayoutWithTopbar,
        CardComponent,
    },
    data() {
        return  {
            card: null,
        };
    },
    created() {
        this.card = new Card({
            id: 'legend',
            front: 'card_legend.svg',
        });
    },
    computed: {
        content() {
            return parseMarkdown(this.$t('markdown.explanation'));
        },
    },
};
</script>
