import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { cards } from '@/assets/cards.js';
import { trainer } from '@/store/modules/trainer.js';
import { filter } from '@/store/modules/filter.js';
import { i18n } from '@/store/modules/i18n.js';
import { filterCards } from '@/functional/filterCards';
import { cardBackIsEqual } from '@/functional/cardBackIsEqual';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: ['trainer', 'i18n'],
});

Vue.use(Vuex);

export const store = new Vuex.Store({
    plugins: [vuexLocal.plugin],
    state: () => ({
        cards,
    }),
    modules: {
        trainer,
        filter,
        i18n,
    },
    getters: {
        findCardsWithFilter: (state) => (filter) => {
            return filterCards(filter, state.cards);
        },
        filteredCards: (state, getters) => {
            return getters.findCardsWithFilter(state.filter);
        },
        uniqueScaleDegrees: (state, getters) => {
            return state.cards.sort((a ,b) => a.scaleDegreeOrdering > b.scaleDegreeOrdering ? 1 : -1).map(card => card.scaleDegree).reduce((accumulator, currentValue) => {
                if(!accumulator.includes(currentValue)) {
                    accumulator.push(currentValue);
                }
                return accumulator;
            }, []);
        },
        cardsWithUniqueBack: (state)  => {
            return state.cards.reduce((accumulator, currentValue) => {
                if(!accumulator.find(c => cardBackIsEqual(c, currentValue))) {
                    accumulator.push(currentValue);
                }
                return accumulator;
            }, []);
        },
    },
});
