import { shuffle } from '@/functional/utils';

const cardMatchesFilter = (filter, card) => {
    if (filter.filters.includes(card.category)) {
        return true;
    }

    if (filter.filters.includes('suspended') && card.suspended) {
        return true;
    }

    if (filter.filters.includes('affectChord') && card.affectChord) {
        return true;
    }

    if (filter.filters.includes('enriched') && card.enriched) {
        return true;
    }

    if (filter.filters.includes('passingChord') && card.passingChord) {
        return true;
    }

    if (filter.filters.includes('saltation') && card.saltation) {
        return true;
    }

    if (filter.filters.includes('otherOther') && card.otherOther) {
        return true;
    }

    return false;
};

const cardMatchesScaleDegree = (filter, card) => {
    return filter.scaleDegrees.includes(card.scaleDegree);
};

export const filterCards = (filter, cards) => {
    const filteredCards = cards.filter(card => {
        const scaleDegreeMatches = cardMatchesScaleDegree(filter, card);
        const filterMatches = cardMatchesFilter(filter, card);
        return filter.scaleDegrees.length ? scaleDegreeMatches && filterMatches : filterMatches;
    });

    if(filter.orderBy === 'random') {
        return shuffle(filteredCards);
    }

    filteredCards.sort((a, b) => {
        if(filter.orderBy === 'scaleDegree') {
            return a.scaleDegreeOrdering > b.scaleDegreeOrdering ? 1 : -1;
        }
        if(filter.orderBy === 'deck') {
            if (a.deckOrdering > b.deckOrdering) return 1;
            else if (a.deckOrdering < b.deckOrdering) return -1;
            else return a.scaleDegreeOrdering > b.scaleDegreeOrdering ? 1 : -1;
        }
        if(filter.orderBy === 'category') {
            if (a.categoryOrdering > b.categoryOrdering) return 1;
            else if (a.categoryOrdering < b.categoryOrdering) return -1;
            else return a.scaleDegreeOrdering > b.scaleDegreeOrdering ? 1 : -1;
        }

        return 0;
    });

    return filteredCards;
};
