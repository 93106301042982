<template>
    <layout-with-topbar :title="$t('title.about')" :small="true">
        <div class="content-container" v-html="content"></div>
    </layout-with-topbar>
</template>

<script>
import LayoutWithTopbar from '@/layouts/LayoutWithTopbar';
import { parseMarkdown } from '@/functional/parse-markdown';

export default {
    components: {
        LayoutWithTopbar,
    },
    computed: {
        content() {
            return parseMarkdown(this.$t('markdown.about'));
        },
    },
};
</script>
