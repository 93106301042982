import { shuffle } from '@/functional/utils';
import { cardBackIsEqual } from '@/functional/cardBackIsEqual';

export const Trainer = class {
    constructor(options, cards, choicesCards) {
        this.options = Object.assign({
            numberOfChoices: 3,
        }, options);
        this.choicesCards = choicesCards;
        this.setCards(cards);
    }

    setCards(cards) {
        this.cards = [...cards];
        this.resetCurrentCard();
    }

    removeCurrentCard() {
        if (this.currentCard) {
            this.removeCard(this.currentCard);
        }
    }

    removeCard(card) {
        this.cards.splice(this.cards.findIndex(c => c.id === card.id), 1);
    }

    resetCurrentCard() {
        this.currentCard = this.cards[Math.floor(Math.random() * this.cards.length)];
        this.resetRandomChoices();
    }

    resetRandomChoices() {
        if (this.currentCard) {
            const choices = this.choicesCards.filter(c => !cardBackIsEqual(c, this.currentCard));
            shuffle(choices);
            this.choices = shuffle([this.currentCard, ...choices.slice(0, this.options.numberOfChoices - 1)]);
        } else {
            this.choices = [];
        }
    }
};
