import informations from '@/i18n/translations/informations.de.md';
import explanation from '@/i18n/translations/explanation.de.md';
import about from '@/i18n/translations/about.de.md';

export const de = {
    message: {
        locale: 'Sprache',
        noOptionSelected: 'Keine Auswahl',
        contextsAndSoundsOfTheRuleOfTheOctave: 'Kontexte und Klänge der Oktavregel',
        variantsOfTheRuleOfTheOctave: 'Varianten der Oktavregelklänge',
        otherContexts: 'Weitere Kontexte',
        affectChords: 'Affektakkorde',
        suspensions: 'Vorhalte',
        passingChord: 'Durchgangsakkorde',
        jumpingBassTones: 'Springende Basstöne',
        other: 'Andere',
        scaleDegrees: 'Bassstufen',
        byScaleDegrees: 'Nach Stufen',
        random: 'Zufällig',
        ordering: 'Sortierung',
        filters: 'Filter',
        dragAndDropCards: 'Karten aus der oberen Ansicht hierher ziehen',
        levelTitle: 'Level {level} ({count})',
        cardsLeftInDeck: 'Keine Karten im Deck | Noch eine Karte | Noch {count} Karten',
        displayedCards: 'Angezeigte Karten',
        resetGame: 'Spielstand zurücksetzen',
        license: 'Lizenz',
        version: 'Version {version}',
    },
    title: {
        home: 'Startseite',
        browser: 'Kennenlernen',
        trainer: 'Trainieren',
        compose: 'Zusammensetzen',
        backgroundInformationsAndGoal: 'Hintergrund und Ziele',
        explanationOfTheSymbols: 'Erklärung der Symbole',
        about: 'Über das Projekt',
        appTitle: 'Kartimento',
    },
    markdown: {
        informations,
        explanation,
        about,
    },
};
