<template>
    <layout-with-topbar :title="$t('title.trainer')">
        <card-filter :show-order-by-filter="false" :show-reset-game-button="true" @reset-trainer="resetTrainerListener"></card-filter>
        <div class="grid grid-cols-2 md:grid-cols-5 gap-3">
            <trainer-level v-for="level in maxLevel" :key="level" :level="level" @load-cards="loadCardsListener"></trainer-level>
        </div>
        <div v-if="trainer.cards.length">
            {{ $tc('message.cardsLeftInDeck', trainer.cards.length) }}

            <div class="w-1/2 md:w-1/3 mx-auto">
                <card :card="trainer.currentCard" show-face="front" :flappable="false" />
            </div>
            <div class="mt-4">
                <div class="grid gap-4" :class="gridCols">
                    <card v-for="card in trainer.choices" :key="card.id" :card="card" show-face="back" :flappable="false" @click="clickListener" :theme="getCardTheme(card)" />
                </div>
            </div>
        </div>
        <div v-else>{{ $tc('message.cardsLeftInDeck', trainer.cards.length) }}</div>
    </layout-with-topbar>
</template>

<script>
import Card from '@/components/Card';
import LayoutWithTopbar from '@/layouts/LayoutWithTopbar';
import TrainerLevel from '@/components/TrainerLevel';
import CardFilter from '@/components/CardFilter';
import { Trainer } from '@/classes/trainer';
import { maxLevel } from '@/store/modules/trainer';

// prevent tailwind purge
// grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 grid-cols-7 grid-cols-8 grid-cols-9 grid-cols-10 grid-cols-11 grid-cols-12

export default {
    components: {
        Card,
        LayoutWithTopbar,
        TrainerLevel,
        CardFilter,
    },
    data() {
        return {
            maxLevel,
            trainer: null,
            incorrectSelectedCard: null,
        };
    },
    created() {
        this.$store.dispatch('trainer/addMissingCards');
        this.trainer = new Trainer({
            numberOfChoices: 5,
        }, this.cards, this.$store.getters.cardsWithUniqueBack);
    },
    watch: {
        cards(value) {
            this.trainer.setCards(value);
        },
    },
    computed: {
        cards() {
            return this.$store.getters.filteredCards;
        },
        gridCols() {
            return `grid-cols-${Math.min(12, this.trainer.options.numberOfChoices)}`;
        },
        getCardTheme() {
            return (card) => {
                if(this.incorrectSelectedCard) {
                    if(this.trainer.currentCard.id === card.id) {
                        return 'correct';
                    }
                    if(this.incorrectSelectedCard.id === card.id) {
                        return 'incorrect';
                    }

                    return 'uninvolved';
                }
                return 'default';
            };
        },
    },
    methods: {
        loadCardsListener(cards) {
            this.incorrectSelectedCard = null;
            this.trainer.setCards(cards);
        },
        resetTrainerListener() {
            this.incorrectSelectedCard = null;
            this.trainer.setCards(this.$store.getters['trainer/getLevelCards'](1));
        },
        clickListener(card) {
            if(card.id === this.trainer.currentCard.id) {
                if(!this.incorrectSelectedCard) {
                    this.$store.dispatch('trainer/success', this.trainer.currentCard);
                }
                this.trainer.removeCurrentCard();
                this.trainer.resetCurrentCard();
                this.incorrectSelectedCard = null;
            } else {
                if(!this.incorrectSelectedCard) {
                    this.$store.dispatch('trainer/error', this.trainer.currentCard);
                }
                this.incorrectSelectedCard = card;
            }
        },
    },
};
</script>
