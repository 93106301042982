import informations from '@/i18n/translations/informations.en.md';
import explanation from '@/i18n/translations/explanation.en.md';
import about from '@/i18n/translations/about.en.md';

export const en = {
    message: {
        locale: 'Language',
        noOptionSelected: 'Nothing selected',
        contextsAndSoundsOfTheRuleOfTheOctave: 'Contexts and chords from the Rule of the Octave',
        variantsOfTheRuleOfTheOctave: 'Variants of chords from the Rule of the Octave',
        otherContexts: 'Other contexts',
        affectChords: 'Affect chords',
        suspensions: 'Suspensions',
        passingChord: 'Passing chords',
        jumpingBassTones: 'Leaping bass tones',
        other: 'Other',
        scaleDegrees: 'Scale degrees',
        byScaleDegrees: 'By scale degrees',
        random: 'Random',
        ordering: 'Ordering',
        filters: 'Filter',
        dragAndDropCards: 'Drag cards from above and drop them here',
        levelTitle: 'Level {level} ({count})',
        cardsLeftInDeck: 'No cards in deck | One card left | {count} cards left',
        displayedCards: 'Displayed cards',
        resetGame: 'Reset game',
        license: 'License',
        version: 'Version {version}',
    },
    title: {
        home: 'Home',
        browser: 'Get acquainted',
        trainer: 'Practice',
        compose: 'Put together',
        backgroundInformationsAndGoal: 'Background and objectives',
        explanationOfTheSymbols: 'Explanation of the symbols',
        about: 'About the project',
        appTitle: 'Kartimento',
    },
    markdown: {
        informations,
        explanation,
        about,
    },
};
