<template>
    <button type="button" class="rounded-sm transition-shadow duration-300 cursor-pointer p-2 w-full hover:shadow-inner text-white text-center focus:outline-none focus:ring" @click="onClick" :class="`button-${type}`">
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'default',
        },
    },
    methods: {
        onClick(event) {
            this.$emit('click', event);
        },
    },
};
</script>

<style lang="scss" scoped>
.button-default {
    @apply bg-blue-400;

    &:focus {
        @apply ring-blue-200;
    }
}

.button-danger {
    @apply bg-red-400;

    &:focus {
        @apply ring-red-200; 
    }
}
</style>
