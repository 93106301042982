import { Card } from '@/classes/card';

export const cards = [
    new Card({
        id: '353aa34e-4d61-44c8-b370-5153f5a7d326',
        front: 'card_00001.svg',
        back: 'card_00002.svg',
        scaleDegree: '1',
        figuredBass: '5 3',
        deck: 'red',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: [],
    }),
    new Card({
        id: '7a06683f-8f04-47ea-9a23-51abee869128',
        front: 'card_00003.svg',
        back: 'card_00004.svg',
        scaleDegree: '2',
        figuredBass: '6',
        deck: 'red',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: true,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['ascending', 'descending'],
    }),
    new Card({
        id: '0e6cdf9d-cfc0-4352-8416-3c5a15cee24e',
        front: 'card_00005.svg',
        back: 'card_00006.svg',
        scaleDegree: '2',
        figuredBass: '5 3',
        deck: 'blue',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['saltation'],
    }),
    new Card({
        id: '9cd81008-addd-47f5-be95-9b0a5b89cfa0',
        front: 'card_00007.svg',
        back: 'card_00008.svg',
        scaleDegree: '3',
        figuredBass: '6',
        deck: 'red',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: [],
    }),
    new Card({
        id: '9ffc78db-d637-4936-92fe-00643c90a0d8',
        front: 'card_00009.svg',
        back: 'card_00010.svg',
        scaleDegree: '4',
        figuredBass: '5 3',
        deck: 'blue',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['saltation'],
    }),
    new Card({
        id: '572f57a0-53e9-4a75-bb22-36bf03581433',
        front: 'card_00011.svg',
        back: 'card_00012.svg',
        scaleDegree: '4',
        figuredBass: '6 5',
        deck: 'red',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: true,
        majorSixth: false,
        passingChord: false,
        progression: ['ascending'],
    }),
    new Card({
        id: '7ebdbb88-1b33-4f1d-81dc-d102e0ed2f67',
        front: 'card_00013.svg',
        back: 'card_00014.svg',
        scaleDegree: '4',
        figuredBass: '2',
        deck: 'red',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: true,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: true,
        progression: ['descending'],
    }),
    new Card({
        id: '6f044950-0654-4dbc-b72c-7c935467690f',
        front: 'card_00015.svg',
        back: 'card_00016.svg',
        scaleDegree: '4',
        figuredBass: '5 3',
        deck: 'blue',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['descending'],
    }),
    new Card({
        id: '99bc83c0-cf98-4cf8-9ee1-1e08077baae7',
        front: 'card_00017.svg',
        back: 'card_00018.svg',
        scaleDegree: '5',
        figuredBass: '5 3',
        deck: 'red',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: true,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: [],
    }),
    new Card({
        id: '0b873506-085b-4af3-a39b-e2d0aa1a5c0a',
        front: 'card_00019.svg',
        back: 'card_00020.svg',
        scaleDegree: '5',
        figuredBass: '5 4',
        deck: 'blue',
        affectChord: false,
        suspended: true,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: [],
    }),
    new Card({
        id: '7b978f15-4857-4d1b-8d22-7f0ce69c298f',
        front: 'card_00021.svg',
        back: 'card_00022.svg',
        scaleDegree: '5',
        figuredBass: '6 4',
        deck: 'blue',
        affectChord: false,
        suspended: 'double',
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: [],
    }),
    new Card({
        id: 'a0674495-b3ea-4e35-81b1-5af38adc15a7',
        front: 'card_00023.svg',
        back: 'card_00024.svg',
        scaleDegree: '6',
        figuredBass: '6',
        deck: 'red',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['ascending'],
    }),
    new Card({
        id: '3382ce8c-9e3f-4ce4-b2a5-5671475c5705',
        front: 'card_00025.svg',
        back: 'card_00026.svg',
        scaleDegree: '6',
        figuredBass: '6',
        deck: 'red',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: true,
        passingChord: false,
        progression: ['descending'],
    }),
    new Card({
        id: '7210be26-bbe6-41f3-bd80-98ab472a6b67',
        front: 'card_00027.svg',
        back: 'card_00028.svg',
        scaleDegree: '6',
        figuredBass: '5 3',
        deck: 'blue',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['saltation'],
    }),
    new Card({
        id: 'b45d5478-44be-434f-9a3e-da55f1743139',
        front: 'card_00029.svg',
        back: 'card_00030.svg',
        scaleDegree: '6',
        figuredBass: '5 3',
        deck: 'blue',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: [],
    }),
    new Card({
        id: 'f945df9d-49e0-4bcc-8e7b-bcd8ba49c29d',
        front: 'card_00031.svg',
        back: 'card_00032.svg',
        scaleDegree: '7',
        figuredBass: '6 5',
        deck: 'red',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['ascending'],
    }),
    new Card({
        id: 'ea7136fc-4091-4254-bdee-e4c625ac39b4',
        front: 'card_00033.svg',
        back: 'card_00034.svg',
        scaleDegree: '7',
        figuredBass: '6',
        deck: 'red',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['descending'],
    }),
    new Card({
        id: '2229ff0f-c0b8-45fc-aaae-005d9a9902b6',
        front: 'card_00035.svg',
        back: 'card_00036.svg',
        scaleDegree: '#4',
        figuredBass: '6 5',
        deck: 'blue',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: [],
    }),
    new Card({
        id: '68278b04-26ba-4ca0-bd49-a0db53f35519',
        front: 'card_00037.svg',
        back: 'card_00038.svg',
        scaleDegree: '2',
        figuredBass: '4 3',
        deck: 'red',
        affectChord: false,
        suspended: false,
        enriched: true,
        semitoniumModi: true,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['ascending', 'descending'],
    }),
    new Card({
        id: 'ed831b12-e545-4599-97f9-fac7b093a8fd',
        front: 'card_00039.svg',
        back: 'card_00040.svg',
        scaleDegree: '6',
        figuredBass: '4 3',
        deck: 'red',
        affectChord: false,
        suspended: false,
        enriched: true,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: true,
        passingChord: false,
        progression: ['descending'],
    }),
    new Card({
        id: 'efa3924b-68aa-411a-bfc7-64a66307b9aa',
        front: 'card_00041.svg',
        back: 'card_00042.svg',
        scaleDegree: '4',
        figuredBass: 'b6',
        deck: 'blue',
        affectChord: true,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['ascending'],
    }),
    new Card({
        id: '7d758e64-68b0-4436-b93f-7ca07f776fdb',
        front: 'card_00043.svg',
        back: 'card_00044.svg',
        scaleDegree: '6',
        figuredBass: '#6',
        deck: 'blue',
        affectChord: true,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['descending'],
    }),
    new Card({
        id: 'a7475959-f602-42bc-af9c-19975eb6950c',
        front: 'card_00045.svg',
        back: 'card_00046.svg',
        scaleDegree: '6',
        figuredBass: '#6 4 3',
        deck: 'blue',
        affectChord: true,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['descending'],
    }),
    new Card({
        id: '935cc316-c5d0-4864-ae13-52ecbe057da6',
        front: 'card_00047.svg',
        back: 'card_00048.svg',
        scaleDegree: '6',
        figuredBass: '#6 5',
        deck: 'blue',
        affectChord: true,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['descending'],
    }),
    new Card({
        id: 'b1f9d02a-474b-437e-aabe-e0ad024feb63',
        front: 'card_00049.svg',
        back: 'card_00050.svg',
        scaleDegree: '7',
        figuredBass: '7',
        deck: 'blue',
        affectChord: true,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['ascending'],
    }),
    new Card({
        id: '262f3d2c-b300-47ce-87af-0b949701679d',
        front: 'card_00051.svg',
        back: 'card_00052.svg',
        scaleDegree: '#4',
        figuredBass: '7',
        deck: 'blue',
        affectChord: true,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: [],
    }),
    new Card({
        id: '0fd59648-96c5-4f27-a3c9-a4431e06c8d5',
        front: 'card_00053.svg',
        back: 'card_00054.svg',
        scaleDegree: '2',
        figuredBass: '5 6',
        deck: 'blue',
        affectChord: true,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['ascending'],
    }),
    new Card({
        id: '3a6a35cf-739d-4e9c-8a0d-2457d038b68a',
        front: 'card_00055.svg',
        back: 'card_00056.svg',
        scaleDegree: '2',
        figuredBass: '6 5',
        deck: 'blue',
        affectChord: true,
        suspended: false,
        enriched: false,
        semitoniumModi: true,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['ascending'],
    }),
    new Card({
        id: '9e1d6e93-5157-4fff-80f8-b058ffd5010a',
        front: 'card_00057.svg',
        back: 'card_00058.svg',
        scaleDegree: '4',
        figuredBass: '3 4',
        deck: 'blue',
        affectChord: true,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['descending'],
    }),
    new Card({
        id: '256c2099-b969-42b7-aaeb-6b99622d277e',
        front: 'card_00059.svg',
        back: 'card_00060.svg',
        scaleDegree: '4',
        figuredBass: '4 3',
        deck: 'blue',
        affectChord: true,
        suspended: false,
        enriched: false,
        semitoniumModi: true,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['descending'],
    }),
    new Card({
        id: 'e5913607-a08f-4b4c-ae21-56d89d68fa69',
        front: 'card_00061.svg',
        back: 'card_00062.svg',
        scaleDegree: '6',
        figuredBass: '2',
        deck: 'blue',
        affectChord: true,
        suspended: false,
        enriched: false,
        semitoniumModi: true,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: false,
        progression: ['descending'],
    }),
    new Card({
        id: '36b94c4a-10d3-4fc0-879a-bb0152e15296',
        front: 'card_00063.svg',
        back: 'card_00064.svg',
        scaleDegree: '5',
        figuredBass: '6 4',
        deck: 'blue',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: true,
        progression: [],
    }),
    new Card({
        id: 'af42b852-10a7-430e-ad9c-4c1e34fdaaa6',
        front: 'card_00065.svg',
        back: 'card_00066.svg',
        scaleDegree: '1',
        figuredBass: '6 4',
        deck: 'blue',
        affectChord: false,
        suspended: false,
        enriched: false,
        semitoniumModi: false,
        quintaSyncopata: false,
        majorSixth: false,
        passingChord: true,
        progression: [],
    }),
];
