<template>
    <div @click="onClick" class="rounded-sm shadow-sm border border-gray-300 hover:bg-gray-50 cursor-pointer">
        <h2 class="border-gray-300 px-3 py-3 border-b-0 md:border-b">{{ $tc('message.levelTitle', countCardsInLevel, {level}) }}</h2>
        <div class="card-preview-container hidden md:block" :class="numerOfPreviewCardsClass">
            <div class="card-preview-item" v-for="card in getFirstLevelCards" :key="card.id">
                <card :card="card" :flappable="false"/>
            </div>
        </div>
    </div>
</template>

<script>
import Card from '@/components/Card.vue';

export default {
    components: {
        Card,
    },
    props: {
        level: {
            type: Number,
            required: true,
        },
        numberOfPreviewCards: {
            type: Number,
            default: 5,
        },
    },
    computed: {
        numerOfPreviewCardsClass() {
            return `count-${Math.min(this.countCardsInLevel, this.numberOfPreviewCards)}`;
        },
        countCardsInLevel() {
            return this.getLevel.length;
        },
        getLevel() {
            return this.$store.getters['trainer/getLevel'](this.level);
        },
        getLevelCards() {
            return this.$store.getters['trainer/getLevelCards'](this.level);
        },
        getFirstLevelCards() {
            return this.getLevelCards.slice(0, this.numberOfPreviewCards);
        },
    },
    methods: {
        onClick(event) {
            event.stopPropagation();
            this.$emit('load-cards', this.getLevelCards);
        },
    },
};
</script>

<style scoped lang="scss">
    $deg: 20deg;

    .card-preview-container {
        position: relative;
        height: 90px;

        @apply my-3;

        @for $i from 1 through 5 {
            &.count-#{$i} {
                .card-preview-item {
                    @for $j from 1 through $i {
                        &:nth-child(#{$j}) {
                            transform: rotateZ($j * $deg - $i / 2 * $deg - $deg / 2) translateX(-50%);
                        }
                    }
                }
                &:hover {
                    .card-preview-item {
                        @for $j from 1 through $i {
                            &:nth-child(#{$j}) {
                                left: #{$j * 100% / $i - 100% / $i / 2};
                                transform: rotateX(0deg) translateX(-50%);
                            }
                        }
                    }
                }
            }
        }
    }

    .card-preview-item {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50px;
        transform-origin: 0 100% 0;
        transition: all .4s ease-in-out;
        transform: translateX(-50%);
    }
</style>
