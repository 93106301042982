<template>
    <layout-with-topbar :title="$t('title.home')">
        <div class="p-3">
            <div class="m-auto text-center sm:w-2/3 md:w-1/2">
                <img :src="require('@/assets/images/kartimento_titel.svg')" alt="Kartimento" class="w-full">
                <div class="grid grid-cols-2 md:grid-cols-3 gap-10 sm:gap-3 my-5">
                    <div>
                        <a href="https://www.mh-freiburg.de">
                            <img :src="require('@/assets/images/mhfr-logo.svg')" alt="Hochschule für Musik Freiburg" class="w-full">
                        </a>
                    </div>
                    <div class="hidden md:block"></div>
                    <div>
                        <a href="https://www.mh-freiburg.de/forschung/freiburger-forschungs-und-lehrzentrum-musik/das-freiburger-forschungs-und-lehrzentrum-musik">
                            <img :src="require('@/assets/images/fzm_logo.jpg')" alt="Freiburger Forschungs- und Lehrzentrum Musik" class="w-full">
                        </a>
                    </div>
                </div>
                <div class="my-5 nav">
                    <ul>
                        <li><router-link :to="{name: 'informations'}">{{ $t('title.backgroundInformationsAndGoal') }}</router-link></li>
                        <li><router-link :to="{name: 'explanation'}">{{ $t('title.explanationOfTheSymbols') }}</router-link></li>
                    </ul>
                    <ul>
                        <li><router-link :to="{name: 'browser'}" class="playable">{{ $t('title.browser') }}</router-link></li>
                        <li><router-link :to="{name: 'trainer'}" class="playable">{{ $t('title.trainer') }}</router-link></li>
                        <li><router-link :to="{name: 'compose'}" class="playable">{{ $t('title.compose') }}</router-link></li>
                    </ul>
                    <ul>
                        <li><router-link :to="{name: 'about'}">{{ $t('title.about') }}</router-link></li>
                    </ul>
                </div>
                <a href="https://glarean.mh-freiburg.de/git/digiplay/kartimento"> {{ $t('message.version', {version}) }}</a>
            </div>
        </div>
    </layout-with-topbar>
</template>

<script>
import LayoutWithTopbar from '@/layouts/LayoutWithTopbar';

export default {
    components: {
        LayoutWithTopbar,
    },
    data() {
        return {
            version: process.env.VERSION,
        };
    },
};
</script>

<style lang="scss" scoped>
.nav ul {
    li a {
        @apply border-solid border border-blue-400 p-5 text-lg my-3 rounded block transition-colors duration-300;

        &.playable {
            @apply bg-blue-200;
        }

        &:hover {
            @apply bg-blue-400 text-white;
        }
    }
}
</style>
