export const i18n = {
    namespaced: true,
    state: () => ({
        locale: 'de',
    }),
    mutations: {
        setLocale(state, value) {
            state.locale = value;
        },
    },
};
