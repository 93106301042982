import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import { router } from '@/router';

if (process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
    Vue.use(VueAnalytics, {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID,
        router,
        debug: {
            enabled: process.env.NODE_ENV !== 'production',
            // sendHitTask: process.env.NODE_ENV === 'production',
        },
        beforeFirstHit() {
            // Vue.$ga.set('anonymizeIp', true);
        },
        set: [
            { field: 'anonymizeIp', value: true },
        ],
    });
}
