<template>
    <div class="flex flex-col">
        <div v-if="showLabel" class="text-gray-400 text-xs pb-1 flex-shrink-0 flex-grow-0" :class="{'hidden md:block': showLabel && !label}">{{ label }}</div>
        <div :class="showLabel && !label ? 'md:mt-4' : null" class="flex-grow flex-shrink flex">
            <div class="self-center w-full">
               <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showLabel: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
        },
    },
};
</script>
