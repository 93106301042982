<template>
    <choice-type v-model="locale" :choices="locales" :label="$t('message.locale')" :show-label="false" :auto-grow="true"></choice-type>
</template>

<script>
import ChoiceType from '@/components/ChoiceType';

export default {
    data() {
        return {
            locales: [
                {
                    value: 'de',
                    label: 'Deutsch',
                },
                {
                    value: 'en',
                    label: 'English',
                },
            ],
        };
    },
    components:  {
        ChoiceType,
    },
    computed: {
        locale: {
            get() {
                return this.$root.$i18n.locale;
            },
            set(value) {
                this.$root.$i18n.locale = value;
                this.$store.commit('i18n/setLocale', value);
            },
        },
    },
};
</script>
