<template>
    <div class="absolute w-full h-full">
        <div class="bg-gray-100 border-solid border-b-2 border-blue-400 py-1 pl-4 pr-1">
            <div class="flex items-center">
                <nav aria-label="breadcrumb">
                    <ol class="flex leading-none divide-x divide-gray-300">
                        <li class="pr-4 text-gray-700 flex items-center">
                            <router-link :to="{name: 'index'}">
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                </svg>
                            </router-link>
                        </li>
                        <li class="px-4 text-gray-700 flex items-center">
                            <router-link :to="{name: 'explanation'}">
                                <svg  class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </router-link>
                        </li>
                        <li class="px-4 text-gray-700" aria-current="page">{{ title }}</li>
                    </ol>
                </nav>
                <div class="ml-auto">
                    <locale-switcher></locale-switcher>
                </div>
            </div>
        </div>
        <container>
            <div :class="{'md:w-2/3 lg:w-1/2 mx-auto': small}">
                <slot></slot>
            </div>
            <div class="text-center text-sm text-gray-400 mt-3">
                {{ $t('message.license')}}: <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode">CC BY-NC-SA 4.0</a>
            </div>
        </container>
    </div>
</template>

<script>
import Container from '@/components/Container';
import LocaleSwitcher from '@/components/LocaleSwitcher';

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    components:  {
        Container,
        LocaleSwitcher,
    },
};
</script>
