<template>
    <div>
        <div class="card-container" :class="theme" :data-id="card.id">
            <div class="card-container-inner" @click="onClick">
                <vue-flip v-model="flipped" width="100%" height="100%">
                    <template v-slot:front class="front">
                        <div class="card">
                            <img v-if="frontSrc" class="w-full" :src="frontSrc" :alt="card.scaleDegree">
                        </div>
                    </template>
                    <template v-slot:back class="back">
                        <div class="card">
                            <img v-if="backSrc" class="w-full" :src="backSrc" :alt="card.figuredBass">
                        </div>
                    </template>
                </vue-flip>
                <div v-if="deletable" @click="onDelete" class="card-delete-button">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                </div>
            </div>
        </div>
        <div v-if="commentable" @click="$event.stopPropagation()" class="mt-2">
            <input value="" class="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-blue-400 transition-colors">
        </div>
    </div>
</template>

<script>
import { Card } from '@/classes/card';
import VueFlip from 'vue-flip';

export default {
    components: { VueFlip },
    data() {
        return {
            flipped: false,
        };
    },
    props: {
        deletable: {
            type: Boolean,
            default: false,
        },
        commentable: {
            type: Boolean,
            default: false,
        },
        card: {
            type: Card,
            required: true,
        },
        showFace: {
            type: String,
            default: 'front',
        },
        flappable: {
            type: Boolean,
            default: true,
        },
        theme: {
            type: String,
            default: 'default',
        },
    },
    mounted() {
        this.flipped = this.showFace === 'back';
    },
    computed: {
        frontSrc() {
            return this.card.front ? require(`@/assets/images/cards/${this.card.front}`) : null;
        },
        backSrc() {
            return this.card.back ? require(`@/assets/images/cards/${this.card.back}`) : null;
        },
    },
    methods: {
        onClick() {
            this.$emit('click', this.card);
            if(this.flappable) {
                this.flipped = !this.flipped;
            }
        },
        onDelete() {
            this.$emit('remove', this.card);
        },
    },
};
</script>

<style scoped lang="scss">
    .card-container {
        width: 100%;
        height: 0;
        position: relative;
        padding-bottom: 154.1%;

        &.uninvolved {
            @apply opacity-50;
        }

        &.correct div.card {
            @apply bg-green-500;
        }

        &.incorrect div.card {
            @apply bg-red-500;
        }

        .card {
            @apply overflow-hidden shadow-sm border border-gray-300 transition duration-300 cursor-pointer;
        }

        &:hover .card {
            @apply shadow-lg border-gray-400 bg-gray-300;
        }
    }

    .card-container-inner {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    ::v-deep {
        .front, .back {
            width: 100%;
            height: 100%;
        }
    }

    .card-delete-button {
        @apply opacity-0 transition-opacity duration-300 bg-red-600 p-2 absolute bottom-0 right-0 h-9 w-9 text-white cursor-pointer;
    }

    .card-container:hover .card-delete-button {
        @apply opacity-100;
    }
</style>
