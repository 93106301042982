<template>
    <div class="rounded-sm shadow-sm border border-gray-300 transition duration-300 p-3 mb-4">
        <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
            <choice-type v-if="showOrderByFilter" v-model="orderBy" :label="$t('message.ordering')" :choices="orderByChoices"></choice-type>
            <choice-type v-model="filters" multiple :label="$t('message.filters')" :choices="filterChoices"></choice-type>
            <choice-type v-model="scaleDegrees" multiple :label="$t('message.scaleDegrees')" :choices="scaleDegreeChoices"></choice-type>
            <form-group v-if="showResetGameButton" :label="null" :show-label="true">
                <button-component @click="resetTrainer" type="danger">{{ $t('message.resetGame') }}</button-component>
            </form-group>
            <form-group :label="$t('message.displayedCards')">
                {{ $store.getters.filteredCards.length }} von {{ $store.state.cards.length }}
            </form-group>
        </div>
    </div>
</template>

<script>
import ChoiceType from '@/components/ChoiceType';
import FormGroup from '@/components/FormGroup';
import ButtonComponent from '@/components/Button';

export default {
    components: {
        ChoiceType,
        FormGroup,
        ButtonComponent,
    },
    props: {
        showOrderByFilter: {
            type: Boolean,
            default: true,
        },
        showResetGameButton: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        resetTrainer() {
            this.$store.dispatch('trainer/reset');
            this.$emit('reset-trainer');
        },
    },
    computed: {
        scaleDegreeChoices() {
            return this.$store.getters.uniqueScaleDegrees.map(scaleDegree => ({
                value: scaleDegree,
                label: scaleDegree,
            }));
        },
        orderByChoices() {
            return [
                {
                    value: 'scaleDegree',
                    label: this.$t('message.byScaleDegrees'),
                },
                {
                    value: 'random',
                    label: this.$t('message.random'),
                },
            ];
        },
        filterChoices() {
            return [
                {
                    value: 'regola',
                    label: this.$t('message.contextsAndSoundsOfTheRuleOfTheOctave'),
                },
                {
                    value: 'variants',
                    label: this.$t('message.variantsOfTheRuleOfTheOctave'),
                    children: [
                        {
                            value: 'affectChord',
                            label: this.$t('message.affectChords'),
                        },
                        {
                            value: 'suspended',
                            label: this.$t('message.suspensions'),
                        },
                    ],
                },
                {
                    value: 'other',
                    label: this.$t('message.otherContexts'),
                    children: [
                        {
                            value: 'saltation',
                            label: this.$t('message.jumpingBassTones'),
                        },
                        {
                            value: 'passingChord',
                            label: this.$t('message.passingChord'),
                        },
                        {
                            value: 'otherOther',
                            label: this.$t('message.other'),
                        },
                    ],
                },
            ];
        },
        filters: {
            get() {
                return this.$store.state.filter.filters;
            },
            set(value) {
                this.$store.commit('filter/setFilters', value);
            },
        },
        scaleDegrees: {
            get() {
                return this.$store.state.filter.scaleDegrees;
            },
            set(value) {
                this.$store.commit('filter/setScaleDegrees', value);
            },
        },
        orderBy: {
            get() {
                return this.$store.state.filter.orderBy;
            },
            set(value) {
                this.$store.commit('filter/setOrderBy', value);
            },
        },
    },
};
</script>
