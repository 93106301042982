export const filter = {
    namespaced: true,
    state: () => ({
        orderBy: 'scaleDegree',
        filters: ['regola'],
        scaleDegrees: [],
    }),
    mutations: {
        setOrderBy(state, value) {
            if(value == 'random') {
                state.orderBy = null;
            }
            state.orderBy = value;
        },
        setFilters(state, value) {
            state.filters = value;
        },
        setScaleDegrees(state, value) {
            state.scaleDegrees = value;
        },
    },
};
