<template>
    <transition :name="transitionName">
        <router-view></router-view>
    </transition>
</template>

<script>
import { setDocumentTitleByRoute } from '@/functional/setDocumentTitle';

export default {
    data () {
        return {
            transitionName: 'slide-left',
        };
    },
    beforeCreate() {
        this.$root.$i18n.locale = this.$store.state.i18n.locale;
    },
    watch: {
        '$route' (to, from) {
            const toDepth = to.path.replace(/^\/$/g, '').split('/').length;
            const fromDepth = from.path.replace(/^\/$/g, '').split('/').length;
            this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
        },
        '$i18n.locale': function(newVal, oldVal) {
            setDocumentTitleByRoute(this.$route, this.$i18n);
        },
    },
};
</script>

<style scoped lang="scss">
    .slide-left-leave-active,
    .slide-left-enter-active,
    .slide-right-leave-active,
    .slide-right-enter-active {
        transition: all .4s ease-out;
    }
    .slide-left-enter,
    .slide-right-leave-to {
        transform: translateX(100%);
        opacity: 0;
    }
    .slide-left-leave-to,
    .slide-right-enter {
        transform: translateX(-100%);
        opacity: 0;
    }
</style>
