<template>
    <layout-with-topbar :title="$t('title.compose')">
        <card-filter></card-filter>
        <draggable
            class="flex flex-nowrap overflow-x-auto space-x-6 sm:space-x-2"
            :list="$store.getters.filteredCards"
            :group="{ name: 'composition', pull: 'clone', put: false }"
            :clone="cloneCard"
            :sort="false"
        >
            <div class="w-20 flex-grow-0 flex-shrink-0" v-for="card in $store.getters.filteredCards" :key="card.id">
                <card-component :card="card"></card-component>
            </div>
        </draggable>

        <div class="my-5">
            <div v-if="composition.length === 0">
                {{ $t('message.dragAndDropCards') }}
            </div>
            <draggable
                class="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-3 composition-container"
                :list="composition"
                group="composition"
            >
                <card-component :card="card" v-for="(card, index) in composition" :key="card.id" :commentable="true" :deletable="true" @remove="removeListener(index)"></card-component>
            </draggable>
        </div>
    </layout-with-topbar>
</template>

<script>
import draggable from 'vuedraggable';
import { v4 as uuidv4 } from 'uuid';
import LayoutWithTopbar from '@/layouts/LayoutWithTopbar';
import CardComponent from '@/components/Card';
import CardFilter from '@/components/CardFilter';
import { Card } from '@/classes/card';

export default {
    name: 'custom-clone',
    display: 'Custom Clone',
    order: 3,
    components: {
        LayoutWithTopbar,
        CardComponent,
        CardFilter,
        draggable,
    },
    data() {
        return {
            composition: [],
        };
    },
    methods: {
        cloneCard(card) {
            return new Card(Object.assign({}, card.options, {id: uuidv4()}));
        },
        removeListener(index) {
            this.composition.splice(index, 1);
        },
    },
};
</script>

<style lang="scss" scoped>
.composition-container .sortable-ghost {
    @apply opacity-0;
}
</style>
