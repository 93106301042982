import { cards } from '@/assets/cards';

const defaultCardLevels = Object.fromEntries(cards.map(card => [card.id, 1]));

export const maxLevel = 5;

export const trainer = {
    namespaced: true,
    state: () => ({
        cardLevels: { ...defaultCardLevels },
    }),
    mutations: {
        addMissingCard(state, cardId) {
            if (!state.cardLevels[cardId]) {
                state.cardLevels = { ...state.cardLevels, [cardId]: 1 };
            }
        },
        levelUpCard(state, cardId) {
            const currentValue = state.cardLevels[cardId] || 0;
            state.cardLevels = { ...state.cardLevels, [cardId]: Math.min(currentValue + 1, maxLevel) };
        },
        levelDownCard(state, cardId) {
            const currentValue = state.cardLevels[cardId] || 0;
            state.cardLevels = { ...state.cardLevels, [cardId]: Math.max(currentValue - 1, 1) };
        },
        setCardToLevel1(state, cardId) {
            state.cardLevels = { ...state.cardLevels, [cardId]: 1 };
        },
        resetCardLevels(state) {
            state.cardLevels = { ...defaultCardLevels };
        },
    },
    getters: {
        getLevel: (state, getters, rootState, rootGetters) => (level) => {
            return Object.keys(state.cardLevels).filter(key => state.cardLevels[key] === level && rootGetters.filteredCards.map(card => card.id).includes(key));
        },
        getLevelCards: (state, getters, rootState, rootGetters) => (level) => {
            const getLevel = getters.getLevel(level);
            return rootGetters.filteredCards.filter(card => getLevel.includes(card.id));
        },
    },
    actions: {
        reset({ commit, dispatch }) {
            commit('resetCardLevels');
            dispatch('addMissingCards');
        },
        success({ commit }, card) {
            commit('levelUpCard', card.id);
        },
        error({ commit }, card) {
            commit('setCardToLevel1', card.id);
        },
        addMissingCards({commit, state, rootState}) {
            rootState.cards.forEach(card => {
                if (!state.cardLevels[card.id]) {
                    commit('addMissingCard', card.id);
                }
            });
        },
    },
};
