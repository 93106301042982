import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { de } from './translations/de';
import { en } from './translations/en';

Vue.use(VueI18n);

const messages = {
    de,
    en,
};

export const i18n = new VueI18n({
    locale: 'de',
    messages,
});
