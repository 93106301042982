<template>
    <div class="py-2 px-3 cursor-pointer" :class="{
        'bg-blue-100 hover:bg-blue-100': choiceIsSelected(choice.value),
        'hover:bg-gray-100': level === 0,
    }" @click="onClick">
        <div class="flex flex-row items-center space-x-3">
            <div v-if="multiple" class="flex-initial border-2 border-gray-400 rounded-sm w-4 h-4 text-transparent flex-grow-0 flex-shrink-0" :class="{'text-white bg-blue-400 border-blue-400': choiceIsSelected(choice.value)}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="flex-grow">
                {{ choice.label }}
            </div>
        </div>
        <div class="pl-5" v-if="choice.children && choice.children.length">
            <choice-type-option v-for="childChoice in choice.children"  :level="level + 1" :key="childChoice.value" :multiple="multiple"  :choice="childChoice" :value="value" @click-choice="clickChoice">{{ childChoice }}</choice-type-option>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChoiceTypeOption',
    props: {
        level: {
            type: Number,
            default: 0,
        },
        value: null,
        multiple: {
            type: Boolean,
            default: false,
        },
        choice: {
            type: Object,
            required: true,
        },
    },
    computed: {
        choiceIsSelected() {
            return (choice) => this.multiple ? this.value.includes(choice) : this.value === choice;
        },
    },
    methods: {
        onClick(event) {
            event.stopPropagation();
            this.$emit('click-choice', this.choice.value);
        },
        clickChoice(event) {
            this.$emit('click-choice', event);
        },
    },
};
</script>
