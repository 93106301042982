<template>
    <layout-with-topbar :title="$t('title.browser')">
        <card-filter></card-filter>
        <transition-group name="flip-list" tag="div" class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
            <card :card="card" show-face="front" v-for="card in cards" :key="card.id" />
        </transition-group>
    </layout-with-topbar>
</template>

<script>
import Card from '@/components/Card';
import LayoutWithTopbar from '@/layouts/LayoutWithTopbar';
import CardFilter from '@/components/CardFilter';

export default {
    components: {
        Card,
        LayoutWithTopbar,
        CardFilter,
    },
    computed: {
        cards() {
            return this.$store.getters.filteredCards;
        },
    },
};
</script>

<style scoped lang="scss">
    .flip-list-move {
        transition: transform 1s;
    }
</style>
