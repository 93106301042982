import IndexView from '@/views/index';
import BrowserView from '@/views/browser';
import TrainerView from '@/views/trainer';
import InformationsView from '@/views/informations';
import ExplanationView from '@/views/explanation';
import ComposeView from '@/views/compose';
import AboutView from '@/views/about';

export const routes = [
    {
        path: '/',
        name: 'index',
        component: IndexView,
        meta: {
            title: 'title.home',
        }, 
    },
    {
        path: '/get-acquainted',
        name: 'browser',
        component: BrowserView,
        meta: {
            title: 'title.browser',
        }, 
    },
    {
        path: '/practice',
        name: 'trainer',
        component: TrainerView,
        meta: {
            title: 'title.trainer',
        }, 
    },
    {
        path: '/background-and-objectives',
        name: 'informations',
        component: InformationsView,
        meta: {
            title: 'title.backgroundInformationsAndGoal',
        }, 
    },
    {
        path: '/explanation-of-the-symbols',
        name: 'explanation',
        component: ExplanationView,
        meta: {
            title: 'title.explanationOfTheSymbols',
        }, 
    },
    {
        path: '/put-together',
        name: 'compose',
        component: ComposeView,
        meta: {
            title: 'title.compose',
        }, 
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView,
        meta: {
            title: 'title.about',
        }, 
    },
];
